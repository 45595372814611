<template>
  <div>
    <a-card :bordered="false">
      <div class="queryView">
        <div class="queryView-conditions">
          <a-form layout="inline" :form="form">
            <a-form-item label="区域">
              <a-select
                class="form-control"
                :options="sybList"
                v-decorator="['sybCodes']"
                placeholder="请选择"
                allowClear
              ></a-select>
            </a-form-item>
            <a-form-item label="项目">
              <a-select-pro
                class="form-control"
                v-decorator="['prjCodes']"
                placeholder="请选择"
                allowClear
                showSearch
                :options="projectList"
                :dropdownMatchSelectWidth="false"
              ></a-select-pro>
            </a-form-item>
            <a-form-item label="风险类型">
              <a-select
                class="form-control"
                :options="typesMap.deviceRiskType"
                v-decorator="['deviceRiskType', { initialValue: '0' }]"
                placeholder="请选择"
              ></a-select>
            </a-form-item>
            <template v-if="form.getFieldValue('deviceRiskType') === '0'">
              <a-form-item label="时间">
                <a-range-picker v-decorator="['date']" />
              </a-form-item>
              <a-form-item label="处理状态">
                <a-select
                  class="form-control"
                  v-decorator="['status']"
                  placeholder="处理状态"
                  :options="typesMap.status"
                  allowClear
                ></a-select>
              </a-form-item>
            </template>

            <span class="whitespace-nowrap" v-if="form.getFieldValue('deviceRiskType') === '1'">
              <a-form-item label="时间">
                <a-date-picker placeholder="请选择开始时间" show-time v-decorator="['startTime']" />
              </a-form-item>
              <a-form-item label="~" :colon="false">
                <a-date-picker placeholder="请选择结束时间" show-time v-decorator="['endTime']" />
              </a-form-item>
            </span>

            <a-form-item>
              <div class="condition-btns">
                <a-button type="primary" @click="doQuery(1)">查询</a-button>
                <a-button type="default" @click="resetAndQuery">重置</a-button>
              </div>
            </a-form-item>
          </a-form>
        </div>
        <!-- <div class="queryView-actions">
          <a-button icon="plus-circle" @click="add">新增</a-button>
        </div> -->
        <div class="queryView-main">
          <a-table
            class="queryView-table"
            :rowKey="$useRowIndex"
            bordered
            :loading="loading"
            :columns="tableColumns"
            :dataSource="dataSource"
            :pagination="false"
            size="middle"
          ></a-table>
          <base-pagination
            :totalCount.sync="totalCount"
            :pageNo.sync="pageNo"
            :pageSize.sync="pageSize"
            @change="pageChange"
            size="small"
          />
        </div>
      </div>
    </a-card>
    <a-modal :title="modal.title" v-model="modal.visible" @ok="handleOk">
      <a-form class="flex-form">
        <a-form-item label="故障原因" v-if="modal.data.processTypeName">{{ modal.data.processTypeName }}</a-form-item>
        <a-form-item label="故障详情及处置结果" v-if="modal.data.processMemo">{{ modal.data.processMemo }}</a-form-item>
        <a-form-item label="处理人">{{ modal.data.processUserName }}</a-form-item>
        <a-form-item label="处理时间">{{ modal.data.processTime }}</a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { queryViewMixin } from '@/utils/mixin'
import ASelectPro from '@/components/SelectPro.vue'
import { getProjectList, getSybList } from '@/service/getData/aie'
import { getTypeName } from '@/utils'
import moment from 'moment'

export default {
  components: {
    ASelectPro,
  },
  mixins: [queryViewMixin],

  data() {
    return {
      form: this.$form.createForm(this),
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      loading: false,
      dataSource: [],
      tableColumns: [],
      projectList: [],
      sybList: [],
      modal: {
        visible: false,
        title: '',
        data: {},
      },
    }
  },
  computed: {
    typesMap() {
      return {
        riskLevel: [
          {
            label: '高风险',
            value: '1',
          },
          {
            label: '中风险',
            value: '2',
          },
          {
            label: '低风险',
            value: '3',
          },
        ],
        entrance: [
          {
            label: '进',
            value: '0',
          },
          {
            label: '出',
            value: '1',
          },
          {
            label: '未知',
            value: '-1',
          },
        ],
        deviceRiskType: [
          {
            label: '离线风险',
            value: '0',
          },
          {
            label: '无过车记录',
            value: '1',
          },
        ],
        status: [
          {
            label: '未处理',
            value: '0',
          },
          {
            label: '已处理',
            value: '1',
          },
          // {
          //   label: '无需处理',
          //   value: '2',
          // },
        ],
      }
    },
  },
  methods: {
    getTableColumns(v) {
      const tableColumns0 = [
        {
          title: '区域',
          width: '15%',
          align: 'center',
          dataIndex: 'sybName',
        },
        {
          title: '项目名称',
          width: '15%',
          align: 'center',
          dataIndex: 'prjName',
        },

        {
          title: '门',
          width: '10%',
          align: 'center',
          dataIndex: 'door',
        },
        {
          title: '进出方向',
          width: '15%',
          align: 'center',
          dataIndex: 'entrance',
          customRender: (text) => {
            return (
              <div>
                {text.split(',').map((e) => {
                  return <a-tag>{getTypeName(e, this.typesMap.entrance)}</a-tag>
                })}
              </div>
            )
          },
        },
        {
          title: '异常设备列表',
          width: '15%',
          align: 'center',
          dataIndex: 'cameraIndexName',
          customRender: (text) => {
            return (
              <div>
                {text.split(',').map((e) => {
                  return <div>{e}</div>
                })}
              </div>
            )
          },
        },
        {
          title: '掉线时间',
          width: '15%',
          align: 'center',
          dataIndex: 'offlineTime',
        },
        {
          title: '处理状态',
          width: 120,
          align: 'center',
          dataIndex: 'status',
          customRender: (text, row) => {
            return (
              <div>
                <div>{row.processUserName}</div>
                <div>
                  {text === 0 && <span>未处理</span>}
                  {text === 1 && (
                    <a
                      onClick={() => {
                        this.checkProcess(row)
                      }}
                    >
                      已处理
                    </a>
                  )}
                  {text === 2 && <span class="text-gray-400">无需处理</span>}
                </div>
              </div>
            )
          },
        },
      ]
      const tableColumns1 = [
        {
          title: '区域',
          width: '15%',
          align: 'center',
          dataIndex: 'sybName',
        },
        {
          title: '项目名称',
          width: '15%',
          align: 'center',
          dataIndex: 'prjName',
        },

        {
          title: '门',
          width: '10%',
          align: 'center',
          dataIndex: 'cameraIndexName',
          customRender: (cameraIndexName) => {
            return <div>{cameraIndexName.split('_')[1]}</div>
          },
        },
        {
          title: '进出方向',
          width: '15%',
          align: 'center',
          dataIndex: 'entrance',
          customRender: (text) => {
            return (
              <div>
                {text.split(',').map((e) => {
                  return <a-tag>{getTypeName(e, this.typesMap.entrance)}</a-tag>
                })}
              </div>
            )
          },
        },
        {
          title: '无记录时间段',
          width: '10%',
          align: 'center',
          customRender: (text, record) => {
            return (
              <div>
                {moment(record.startTime).format('YYYY-MM-DD HH:mm:ss')}~
                {moment(record.endTime).format('YYYY-MM-DD HH:mm:ss')}
              </div>
            )
          },
        },
      ]
      if (v === '0') {
        return tableColumns0
      }
      return tableColumns1
    },
    getData(v, pageNo, pageSize) {
      if (v === '0') {
        const params = this.formatTimeRangeParams(this.getPageParams(pageNo, pageSize), 'date', {
          format: 'YYYY-MM-DD',
        })
        return this.$axios.post('/aie/web/device/event/all', {
          ...params,
        })
      }
      const params = this.formatParams(this.getPageParams(pageNo, pageSize), (key, value) => {
        if (key === 'startTime') {
          return { key, value: value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '' }
        }
        if (key === 'endTime') {
          return { key, value: value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '' }
        }
      })
      return this.$axios.post('/aie/web/device/no/pass/record', {
        ...params,
      })
    },
    getTypeName,
    async doQuery(_pageNo = this.pageNo, _pageSize = this.pageSize) {
      this.loading = true
      try {
        const curDeviceRiskType = this.form.getFieldValue('deviceRiskType')
        this.tableColumns = this.getTableColumns(curDeviceRiskType)
        const res = await this.getData(curDeviceRiskType, _pageNo, _pageSize)
        const list = res.data.rows
        const { pageSize, pageNo, totalCount } = res.data
        this.totalCount = totalCount
        this.pageSize = pageSize
        this.pageNo = pageNo
        this.dataSource = list
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async loadProjectList() {
      this.projectList = await getProjectList()
    },
    async loadSybList() {
      this.sybList = await getSybList()
    },
    checkProcess(row) {
      this.modal.visible = true
      this.modal.title = '处理详情'
      this.modal.data = {
        ...row,
      }
    },
    handleOk() {
      this.modal.visible = false
    },
  },
  async mounted() {
    this.loadProjectList()
    this.loadSybList()
    this.doQuery()
  },
}
</script>

<style lang="less" scoped></style>
