import { render, staticRenderFns } from "./DeviceRiskList.vue?vue&type=template&id=3ceb13bc&scoped=true&"
import script from "./DeviceRiskList.vue?vue&type=script&lang=js&"
export * from "./DeviceRiskList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ceb13bc",
  null
  
)

export default component.exports